<template>
  <nav class="navbar navbar-expand-lg bg-white shadow-smd1"
    :style="'top: ' + (cnst.navBarY ?? INIT_SRCH_Y_POS) + 'px;'">
    <div class="container-fluid nav-container gx-0">
      <!-- service logo start -->
      <a href="/" class="navbar-brand">
        <img class="nav-logo" src="/aset/img/icon/logo-generated-navbar.webp" alt="Navigation bar logo of Genereted service" />
      </a>
      <!-- service logo end -->
      <ul class="mobl-icon h-100 list-group-horizontal d-flex d-block d-lg-none">
        <!-- [mobile] my notice menu -->
        <li class="nav-item dropdown icon icon-bell position-relative">
          <div class="dropdown-toggle" v-wave
            id="navbarDropdownMobl" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-sharp fa-regular fa-bell-ring"></i>
            <div v-if="user.isSignedIn && user.getUnReadNotiCnt > 0" class="bdge-cnt">
              <span class="badge red">{{ user.getUnReadNotiCnt }}</span>
            </div>
          </div>
          <drop-down-noti v-show="user.isSignedIn" labl="navbarDropdownMobl" :list="user.getAncmList" />
        </li>
        <!-- [mobile] my notice menu -->

        <!-- [mobile] my cart menu -->
        <li class="nav-item icon icon-bskt">
          <a href="/view/user/dashboard#cart" class="d-flex" role="button" v-wave>
            <i class="fa-regular fa-cart-shopping-fast"></i>
            <div v-if="user.isSignedIn && user.getCartCnt > 0" class="pos-abs-top-right">
              <span class="badge red">{{ user.getCartCnt }}</span>
            </div>
          </a>
        </li>
        <!-- [mobile] my cart menu -->
      </ul>

      <!-- mobile menu toggle(hamburger) button start -->
      <!-- ref. https://mdbootstrap.com/docs/b4/jquery/navigation/hamburger-menu/ -->
      <!-- Collapse button -->
      <button
        class="navbar-toggler mobile-toggle"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div class="animated-icon1"><span></span><span></span><span></span></div>
      </button>
      <!-- mobile menu toggle(hamburger) button end -->

      <div id="navbarToggleExternalContent" class="collapse navbar-collapse menu-items d-flex">

        <div class="right-menu-wrap w-100 d-flex p-justify-content-between">
          <!-- menu list start -->
          <ul class="navbar-nav left-nav nav-pills nav-fill">
<!--            <li class="fw-medium txt-ai">AI</li>-->
            <li class="nav-item position-relative">
              <a href="/view/image/generator" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">
                    AI Image Generator
                  </span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-wind-turbine fs-4 m-0"></i>
                  </span>
                </div>
              </a>
              <img src="/aset/img/icon/icon-free.webp" class="icon-free pos-abs-top-right" />
            </li>

            <li v-if="mode === 'development' || mode === 'staging'" class="nav-item position-relative">
              <a href="/view/video/generator" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">
                    Video Generator
                  </span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-wind-turbine fs-4 m-0"></i>
                  </span>
                </div>
              </a>
              <img src="/aset/img/icon/icon-free.webp" class="icon-free pos-abs-top-right" />
            </li>

            <li class="nav-item position-relative">
              <a href="/view/free/images" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">
                    User-Generated Images
                  </span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-images fs-4 ic-pict m-0"></i>
                  </span>
                </div>
              </a>
              <img src="/aset/img/icon/icon-free.webp" class="icon-free pos-abs-top-right" />
            </li>

            <li v-if="mode === 'development' || mode === 'staging'" class="nav-item position-relative">
              <a href="/view/free/videos" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">
                    Videos
                  </span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-camcorder fs-4 ic-pict m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <!--
            <li class="txt-ai fw-light mx-2">:</li>
            <li class="txt-ai fw-medium text-primary ">
              Marketplace
            </li>
            -->
            <li class="nav-item">
              <a href="/view/stock/images" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text ellipsis d-flex">Stock Images</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-images fs-4 ic-pict m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <li class="nav-item">
              <a href="/view/policy/pricing" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Pricing</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-solid fa-barcode-read fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <li class="nav-item">
              <a href="/view/policy/faq" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">FAQ</span>
                  <span class="m-menu-icon faq d-block d-lg-none">
                    <i class="fa-regular fa-comment-question fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <!--
            <li class="nav-item">
              <router-link v-wave class="nav-link ellipsis"
                to="/view/announcement/rankings">
                <div class="nav-link-chld">
                  <span>Rankings</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-sharp fa-regular fa-ranking-star fs-4 m-0"></i>
                  </span>
                </div>
              </router-link>
            </li>
            -->

            <li class='nav-item'>
              <a href="/view/announcement/events" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Events</span>
                  <span class="m-menu-icon evnt d-block d-lg-none">
                    <i class="fa-sharp fa-regular fa-ranking-star fs-4 ms-2"></i>
                  </span>
                </div>
              </a>
            </li>

            <!-- invisible -->
            <li
              v-if="mode === 'development' || mode === 'staging'"
              class="nav-item icon-devmode shadow-smd3 d-block d-lg-none d-xxl-block"
              :class="mode === 'development' ? 'dev-mode' : 'beta-mode'">
              <div v-if="mode === 'development'">DEV-MODE</div>
              <div v-if="mode === 'staging'">TEST-MODE</div>
            </li>
            <!-- invisible -->

          </ul>
          <!-- menu list end -->

          <!-- right nav -->
          <ul class="navbar-nav right-nav nav-pills d-inline-flex justify-content-end">
            <!-- 'MY' menu button -->
            <!--
            <li class="nav-item my">
              <a href="/view/user/dashboard" class="nav-link" v-wave @click="goToMyPage">
                <div class="nav-link-chld">
                  <span class="text">My</span>
                  <span>
                    <i class="d-block d-lg-none fa-regular fa-head-side-brain fs-4 ms-2 mt-2"></i>
                  </span>
                </div>
              </a>
            </li>
            -->
            <!-- 'MY' menu button -->

            <!-- [PC] my notice menu -->
            <li class="nav-item nav-link icon d-none d-xxl-block icon-bell dropdown">
              <div class="dropdown-toggle"
                   id="navbarDropdownPC" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-sharp fa-regular fa-bell-ring"></i>
                <div v-if="user.isSignedIn && user.getUnReadNotiCnt > 0" class="bdge-cnt">
                  <span class="badge red">{{ user.getUnReadNotiCnt }}</span>
                </div>
              </div>
              <drop-down-noti v-show="user.isSignedIn" labl="navbarDropdownPC" :list="user.getAncmList" />
            </li>
            <!-- [PC] my notice menu -->

            <!-- [PC] my cart menu -->
            <li class="nav-item nav-link icon d-none d-xxl-block icon-bskt">
              <a href="/view/user/dashboard#cart" class="d-flex" role="button" v-wave>
                <i class="fa-regular fa-cart-shopping-fast"></i>
                <div v-if="user.isSignedIn && user.getCartCnt > 0" class="pos-abs-top-right">
                  <span class="badge red">{{ user.getCartCnt }}</span>
                </div>
              </a>
            </li>
            <!-- [PC] my cart menu -->

            <!-- sign in button -->
            <li v-if="!user.isSignedIn" class="nav-item btn-sign btn-sgin">

              <a :href="getSignInUrl()" class="nav-link btn btn-outline-light btn-signin shadow-md" v-wave @click="goToMyPage">
                <div class="d-flex justify-content-center margin-auto">
                  <span class="icon  d-block d-lg-none me-2"><!-- me-2 mt-1 -->
                    <i class="fa-solid fa-person-running-fast"></i>
                    </span>
                  <div class="text">Sign In</div>
                </div>
              </a>
            </li>
            <!-- sign in button -->
            <!-- sign up button -->
            <li v-if="!user.isSignedIn" class="nav-item btn-sign btn-sgup">
              <a :href="getSignUpUrl()" class="nav-link btn btn-outline-light btn-signin shadow-md" v-wave @click="goToMyPage">
                <div class="d-flex justify-content-center margin-auto">
                  <span class="icon me-2 d-block d-lg-none"><!-- me-2 mt-1 -->
                    <i class="fa-regular fa-user-plus"></i>
                  </span>
                  <div class="text">Sign Up</div>
                </div>
              </a>
            </li>
            <!-- sign up button -->

            <li v-if="user.isSignedIn"
              class="nav-item prof-pic-area position-relative m-bt-solid text-end">
              <!-- my(signed in use)r profile area -->
              <sg-in-user @click="goToMyPage" class="fs-4" />
              <!-- my(signed in use)r profile area -->
            </li>
          </ul>
          <!-- right nav -->

          <div class="d-block d-lg-none w-100 btn-pos-abs-btm m-polc-area">
            <div class="text-end txt-comp">
              <p class="fw-normal">
                ⓒ 2024 Generated, Inc.<br class="d-block d-md-none"/>651 N Broad St, Middletown, DE, USA
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import '/public/aset/css/html-navbar.css'
import { onMounted, reactive, watch } from "vue"
import router from "@/rout"
import { user_stor } from "@/stor/user_stor"
import DropDownNoti from '@/view/cpnt/DropDownNoti.vue'
import { cnst_stor } from '@/stor/cnst_stor'
import SgInUser from '@/view/cpnt/SgInUser.vue'

const cnst = cnst_stor()
const host = import.meta.env.VITE_APP_HOST
const user = user_stor()
const mode = import.meta.env.VITE_APP_ENV
const model = reactive({
  userData: null,
  mode: "",
})

watch(
  () => user.getAncmList,
  (param) => {
    model.ancmList = param
  }
)

watch(
  () => user.getUnReadNotiCnt,
  (param) => {
    model.ancmCnt = param
  }
)

onMounted(() => {
  // 표시언어는 브라우저 언어설정(o)을 따름, 접속국가(x)
  document.querySelector(".nav-item")?.addEventListener("click", closeMobileMenu)
  document.querySelector(".mobile-close")?.addEventListener("click", closeMobileMenu)
  document.querySelector(".mobile-toggle")?.addEventListener("click", toggleMobileMenu)
  const url = location.href
  document.querySelectorAll('.nav-link').forEach((it) => {
    if (url.indexOf(it.getAttribute('href'))>-1) {
      it.classList.add('router-link-active')
    } else {
      it.classList.remove('router-link-active')
    }
  })
})

function goToMyPage() {
  console.log('goToMyPage')
  const destUrl = "/view/user/dashboard"
  if (user.isSignedIn) {
    router.push(destUrl)
  } else {
    const r = encodeURIComponent(destUrl)
    router.push(`/view/sign/in?r=${r}`)
  }
  closeMobileMenu()
}

function showMyInfo() {
  user.showMyInfo(true)
  closeMobileMenu()
}

function getSignInUrl() {
  try {
    const q =
      location.search && location.search.startsWith("?r=")
        ? location.search
        : location.pathname === "/" || location.pathname.indexOf("/sign/") > -1
        ? ""
        : "?r=" + encodeURIComponent(location.pathname)
    return `/view/sign/in${q}`
  } catch (e) {
    console.error(`[SGIN] e`, e.message)
    return '/view/sign/in'
  }
}

function getSignUpUrl() {
  try {
    const q =
      location.search && location.search.startsWith("?r=")
        ? location.search
        : location.pathname === "/" || location.pathname.indexOf("/sign/") > -1
        ? ""
        : "?r=" + encodeURIComponent(location.pathname)
    return `/view/sign/up${q}`
  } catch (e) {
    console.error(`[SGUP] e`, e.message)
    return '/view/sign/up'
  }
}

function toggleMobileMenu() {
  const sideMenu = document.getElementById("navbarToggleExternalContent")
  if (sideMenu) {
    if (sideMenu.classList.contains("show")) {
      document.querySelector(".animated-icon1")?.classList.remove("open")
      sideMenu.classList.remove("show")
    } else {
      document.querySelector(".animated-icon1")?.classList.add("open")
      sideMenu.classList.add("show")
    }
  }
}

function closeMobileMenu() {
  document.querySelector(".animated-icon1")?.classList.remove("open")
  document.getElementById("navbarToggleExternalContent")?.classList.remove("show")
}
</script>

<style scoped>
</style>
