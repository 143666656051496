import '@/assets/scss/_custom.scss'
import '@/assets/css/comn.css'
import VWave from 'v-wave'
import router from './rout'
import Popper from 'vue3-popper'
import { i18n } from '@/lcal/i18n'
import ScriptX from 'vue-scriptx'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import LoadScript from 'vue-plugin-load-script'
import App from './App.vue'

if (import.meta.env.VITE_APP_HOST.endsWith("gen8.id")) {
  console.log = function () {}
}

const app = createApp(App)
app.use(ScriptX)
  .use(createPinia())
  .use(VWave, { color: 'green' })
  .use(LoadScript)
  .use(router)
  .use(i18n)
  .component('Popper', Popper)
  .mount('#app')