<template>
  <!-- modal bg (img detail) -->
  <div id="img-detl-mdal" class="modal html stock d-none" tabindex="-1">
    <!-- modal dialog-->
    <div class="modal-dialog modal-xl bg-dark br-2px">
      <!-- modal content-->
      <div id="modal-content" class="modal-content">
        <!-- modal body-->
        <div id="img-modal-body" class="modal-body">

          <div id="stck-img-detl-html" class="container-fluid ctnt-wrap d-flex">
            <div id="detl-non-block-loader" v-if="!model.data" class="loading-inner position-absolute white">
              <img src="/aset/img/icon/icon-loading-white.webp" alt="[Generated] Progress Loader | www.gen8.id"/>
            </div>
          </div>

          <!--
            @showRprtMdal="showRprtMdal"
            @setCurrItemId="setCurrItemId"
            @onQuot="onQuot"
            @onPcseClck="onPcseClck"
          -->

          <img-work-pcse-stp1-pane
            ref="pamtPane"
            v-if="model.step === 2 || model.step === 3"
            :data="model.data"
            @onCartChng="onCartChng"
            :payData="model.payData"
            @onPaySuccess="onPamtSucc"
            @onAddCart="onAddCart"
            :step="model.step"
            :releaseUi="model.releaseUi"
            @onStep2NextClick="onStep2NextClick"
            @onPrev="onPrev"
            @closMdal="closMdal"
          />

        </div>

      </div>
      <!-- modal content -->

      <div v-if="model.brkn" class="pos-abs-center">
        <img src="@/assets/img/icon/ico-file-not-fund.png" />
      </div>
      <div v-if="model.noPermTeen" class="pos-abs-center">
        <img src="/aset/img/icon/icon-permission-teen.webp" class="icon-ctnt-grad"/>
        <p class="text-white text-center fw-light mt-1">Please sign-in to view the content</p>
      </div>

      <btn-close-top-right class="white" @onCloseClick="closMdal" />

    </div>

    <rprt-modal
      v-if="model.showRprtMdal"
      @closeModal="closRprtMdal"
      :grupId="model.grup.grupId"
      :crtrId="model.grup.crtrId"
    />

  </div>
  <!-- modal bg (img detail) -->

  <quot-prvw-modal v-if="model.citeShow" :cite-info="model.citeInfo" @closeModal="model.citeShow=false"/>

</template>

<script setup>
import "/public/aset/css/html-comn.css"
import "/public/aset/css/stck-img.css"
import { onBeforeUnmount, onMounted, reactive, watch, ref } from "vue"
import axis_cstm from "@/util/axis_cstm"
import { user_stor } from "@/stor/user_stor"
import { ResCd, API_ROOT } from "@/util/comn_cnst"
import BtnCloseTopRight from "@/view/btns/BtnCloseTopRight.vue"
import ImgWorkPcseStp1Pane from "@/view/pane/ImgWorkPcseStep1Pane.vue"
import router from "@/rout"
import {
  blockRefresh,
  preventBgScroll,
  releaseBgScroll,
  unblockRefresh
} from "@/util/ui_evnt_util"
import RprtModal from "@/view/modal/RprtModal.vue"
import { mdal_stor } from '@/stor/mdal_stor'
import QuotPrvwModal from '@/view/modal/QuotPrvwModal.vue'
import { initScript } from "/public/aset/js/stck-img"

const mdal = mdal_stor()
const emit = defineEmits(["closeModal"])
const user = user_stor()
const pamtPane = ref()
const detlPane = ref()
const axis = axis_cstm()
const model = reactive({
  loading: true,
  mode: null,
  detlHtml: null,
  isPay: false,
  grup: null,
  step: 1,
  brkn: false,
  noPermTeen: false,
  data: null,
  fromExternal: false,
  imgList: [],
  user: null,
  disabled: true,
  hideCnclBtn: true,
  payData: {},
  rgstStep: "",
  showRprtMdal: false,
  releaseUi: false,
  citeInfo: null,
  param: null
})

watch(
  () => model.step,
  (param) => {
    model.step = param
  }
)

watch(
  () => mdal.getImgDetlData,
  (param) => {
    model.param = param
    openIntn(param)
  }
)

onMounted(() => {
  console.log('[onMounted][ImgWorkDetlModal]')
  blockRefresh()
  if (document.body.offsetWidth < 992) {
    preventBgScroll()
  }
  const imgDetlInfo = mdal.getImgDetlData
  if (imgDetlInfo && imgDetlInfo.b62Id) {
    openIntn(imgDetlInfo)
  } else {
    // 2. 타 사이트에서 브라우저 URL로 바로 넘어오는 경우
    console.log(`[IMG-DETL-POP] opened by direct URL access, external: ${model.fromExternal}, host: ${location.hostname}`)
    model.b62Id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
  }
})

onBeforeUnmount(() => {
  unblockRefresh()
  if (document.body.offsetWidth < 992) {
    releaseBgScroll()
  }
})

function openIntn(imgDetlData) {
  // 1. 온사이트에서 prop으로 넘어오는 경우
  console.log('[IMG-DETL-POP] opened internally', JSON.stringify(imgDetlData))
  if (!imgDetlData) {
    return
  }
  document.getElementById("img-detl-mdal").classList.remove("d-none")
  history.pushState({}, null, `/view/stock/images/${imgDetlData.b62Id}`)
  loadImgDetlInnrPart(imgDetlData.b62Id)
}

function closMdal(e) {
  if (model.step === 3) {
    console.log('closMdal')
    mdal.confirm(
      "You're going through<br/>the checkout process.<br/>Are you sure you want to stop?",
      function() {
        mdal.closImgDetlPopUp()
        location.reload()
      },
      '<i class="fa-solid fa-check me-2"></i>Yes',
      null,
      '<i class="fa-solid fa-xmark-large me-2"></i>Cancel'
    )
  } else {
    mdal.closMdal(e)
  }
}
function closRprtMdal() {
  model.showRprtMdal = false
}

function showRprtMdal() {
  model.showRprtMdal = true
}

// Step2 -> Step3
// customize checkout page: https://docs.stripe.com/checkout/embedded/quickstart#customize-toggle
async function onStep2NextClick() {
  // 1) if member purchase
  if (user.isSignedIn) {
    // Member purchase
    await axis_cstm()
      .get(API_ROOT + "prvt/my/basc")
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          model.payData["user"] = data.rslt
        }
        model.step = 3
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  } else {
    model.step = 3
  }

}

function onQuot() {
  // pblcThumUrl (x) pblcWtmkUrl
  model.citeInfo = {
    crtrNm: model.grup.crtrNm,
    titl: model.grup.titl,
    date: model.grup.aprvDttm.substring(0, 4),
    host: location.host,
    imgUrl: detlPane.value.getThum600Url(),
    refUrl: location.href,
  }
  console.log('data', JSON.stringify(model.citeInfo))
  model.citeShow = true
}

function onAddCart() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "You must be signed in to add to cart.<br/>Are you sure you want to sign in?",
      function () {
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        router.push(
          "/view/sign/in?r=" + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
        )
      },
      '<i class="fa-solid fa-check me-2"></i>Sign in'
    )
  } else {
    let totlPric = (model.payData.imgPricAmt * model.payData.imgCnt + model.payData.pmptPricAmt) / 100
    //Todo
    // ctry 추가
    const param = {
      grupId: model.grup.grupId,
      items: model.payData.items,
      imgIdList: model.payData.imgIdList,
      totlPric: totlPric,
      ctry: 'US',
      crcy: sessionStorage.getItem('crcy'),
      imgCnt: model.payData.imgCnt,
      pmptCnt: model.payData.pmptCnt
    }
    axis_cstm()
      .post(API_ROOT + "prvt/cart", param)
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.REGISTERED) {
          user.updtCartCnt(1)
          mdal.toast('<i class="fa-solid fa-cart-shopping me-2 text-primary"></i> Added to cart!')
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  }
}

function onPcseClck() {
  model.step = 2
}

/**
 * Definition
 * @param param
 */
function onCartChng(param) {
  // 그룹ID, 타이틀, 이미지ID 리스트, 이미지가격ID/이름, 프롬프트가격ID/이름
  // , 할인ID, 할인률,
  if (param==null) {
    console.log('[modal] onCartChng paydata null')
    model.payData = null
  } else {
    console.log('[modal] onCartChng paydata', JSON.stringify(param))
    model.payData = param
  }
}

function onPrev() {
  console.log("onPrev", model.step)
  model.step--
  if (model.step === 2) {
    model.releaseUi = true
  } else {
    model.step = 1
  }
}

function onPamtSucc(param) {
  // ref. https://developer.paddle.com/api-reference/notification-settings/create-notification-setting
  // Todo: 1) Send payment data to server or get by webhook(Notifications)
  // Todo: 2) then router.push
  console.log("[onPamtSucc]", JSON.stringify(param))
  mdal.confirm(
    "Payment has been completed.<br/>Would you like to go to the order list?",
    function () {
      // mdal.closImgDetlPopUp()
      // router.push("/view/user/dashboard")
      location.href = "/view/user/dashboard"
    },
    "Yes",
    closMdal
  )
}

function loadImgDetlInnrPart(b62Id) {
  console.log(`[loadImgDetlInnrPart] ${b62Id}`)
  model.loading = true
  axis.get(API_ROOT + `pblc/stck/imgs/${b62Id}/part`)
    .then((res) => {
      // model.data = res.data
      document.getElementById("stck-img-detl-html").innerHTML = res.data
      initScript()
      // initDetlEvntLsnr()
    })
    .catch((err) => {
      console.error("err", err)
    })
    .finally(() => {
      model.loading = false
    })
}
</script>

<style scoped>
.modal-content {
  border: none;
  background-color: transparent;
}
.modal-body {
  overflow-x: hidden;
  background-color: rgb(33, 37, 41);
}
.img-page > svg {
  font-size: 1.7rem;
  margin-right: 0.5rem;
}

.img-page > div {
  padding-bottom: 0.5rem;
  line-height: 1.4rem;
}

.img-detail hr {
  color: #75b798;
  opacity: 1;
  border-width: 5px;
}

span:last-child {
  margin-right: 0;
}

.creator > svg {
  font-size: 18px;
  height: 18px;
  margin-right: 6px;
  color: #555;
}

.icon-ctnt-grad {
  width: 300px;
}

@media (max-width: 991px) {
  .br-2px {
    border-right-width: 2px;
  }
}

@media (min-width: 992px) {
  .modal-content {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .modal-body {
    overflow-y: hidden;
  }
  .modal-content {
    overflow: hidden;
  }
}
</style>
