import { createRouter, createWebHistory } from 'vue-router'
import { user_stor } from '@/stor/user_stor'
import { BrwsStrgKey } from '@/util/comn_cnst'
import { getToknExprTimeInMinSec, rfshToknSyncFull } from '@/util/auth_func'
import { mdal_stor } from '@/stor/mdal_stor'

const requireAuth = () => (from, to, next) => {
  if (user_stor().isSignedIn) {
    next()
  } else {
    const minLeft = getToknExprTimeInMinSec()[0]
    console.log(`[RqstAuth] min-left ${minLeft}, from router`, from.path)
    const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    const yes = localStorage.getItem(BrwsStrgKey.STOP_TOKN_RQST)
    if (minLeft > 0) {
      // 시간 남았으면 리프레시
      rfshToknSyncFull()
    // } else if (tp && tp.length > 9 && yes && yes === '1') {
    //   // 시간은 없는데 새창 같은 경우
    //   console.log('[RqstAuth] may be new tab')
    //   copyLoclToSess()
    //   rfshToknSyncFull()
    } else {
      // 시간 끝난 경우
      console.log('[RqstAuth] no auth but next')
      user_stor().clerUserData()
      next('/view/sign/in?r=' + encodeURIComponent(from.path))
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/view/MainView.vue')
  },
  {
    path: '/view/announcement/rankings',
    name: 'crtrRank',
    children: [
      {
        path: '/weekly',
        name: 'crtrRankByWeek',
        component: () => import('@/view/RankView.vue')
      },
      {
        path: '/all-time',
        name: 'crtrRankByWeek',
        component: () => import('@/view/RankView.vue')
      },
      {
        path: '',
        name: 'crtrRankByMonth',
        component: () => import('@/view/RankView.vue')
      }
    ]
  },
  {
    path: '/view/announcement/events',
    name: 'ancmEvnt',
    component: () => import('@/view/EvntView.vue')
  },
  {
    path: '/view/policy/faq',
    name: 'faq',
    component: () => import('@/view/FaqView.vue')
  },
  {
    path: '/view/policy/pricing',
    name: 'pric',
    component: () => import('@/view/PricView.vue')
  },
  {
    path: '/view/policy/terms-of-service',
    name: 'tos',
    component: () => import('@/view/TermOfSrvc.vue')
  },
  {
    path: '/view/policy/privacy',
    name: 'prvc',
    component: () => import('@/view/PrvtPolc.vue')
  },
  {
    path: '/view/policy/creator-consent',
    name: 'crtrCsnt',
    component: () => import('@/view/CrtrCsnt.vue')
  },
  {
    path: '/view/stock/images',
    name: 'stockImageList',
    component: () => import('@/view/StckImgView.vue'),
    props: true,
    children: []
  },
  {
    path: '/view/stock/images/:grupId',
    name: 'imgDetl'
  },
  {
    path: '/view/stock/images/:grupId/pay',
    name: 'imgDetlPay'
  },
  {
    path: '/view/free/images',
    name: 'freeImgList',
    props: true,
    children: []
  },
  {
    path: '/view/video/generator',
    name: 'videoGenerator',
    props: true,
    component: () => import('@/view/GenVido.vue')
  },
  {
    path: '/view/free/videos',
    name: 'freeVidoList',
    props: true,
    children: []
  },
  {
    path: '/view/image/generator',
    name: 'imgGen',
    component: () => import('@/view/GenImgView.vue')
  },
  {
    path: '/view/image/edit',
    name: 'imgEdit',
    component: () => import('@/view/GenImgView.vue')
  },
  {
    path: '/view/image/upscale',
    name: 'bulkUpsc',
    component: () => import('@/view/GenImgView.vue')
  },
  {
    path: '/view/image/upscale-view',
    name: 'imgUpsc',
    component: () => import('@/view/GenUpsc.vue')
  },
  {
    path: '/view/payment/result',
    name: 'pamtRslt',
    component: () => import('@/view/StrpPamtSucc.vue')
  },
  {
    path: '/view/payment/download/:id',
    name: 'gestDnld',
    component: () => import('@/view/DnldView.vue'),
    props: true
  },
  {
    path: '/view/sign/in',
    name: 'signIn',
    component: () => import('@/view/SignView.vue'),
    props: true
  },
  {
    path: '/view/sign/up',
    name: 'signUp',
    component: () => import('@/view/SignView.vue'),
    props: true
  },
  {
    path: '/view/sign/verify',
    name: 'vrfyEmal',
    component: () => import('@/view/pane/SignUpPinCodePane.vue'),
    props: true
  },
  {
    path: '/view/sign/ok/:id',
    name: 'vrfySucc',
    props: true,
    component: () => import('@/view/SignPinCodeSucc.vue')
  },
  {
    path: '/view/submission/images',
    name: 'rgstImgs',
    component: () => import('@/view/StckImgRgst.vue'),
    children: [
      {
        path: 'step1',
        name: 'rgstStep1',
        component: () => import('@/view/pane/ImgWorkRgstStep1Pane.vue')
      },
      {
        path: 'step2',
        name: 'rgstStep2',
        component: () => import('@/view/pane/ImgWorkRgstStep2Pane.vue'),
        beforeEnter: requireAuth()
      }
    ]
  },
  {
    path: '/view/user',
    name: 'myPage',
    component: () => import('@/view/laot/MyPageLaot.vue'),
    beforeEnter: requireAuth(),
    children: [
      {
        path: 'dashboard',
        name: 'myDsbd',
        component: () => import('@/view/MyPage.vue')
      },
      {
        path: 'verify',
        name: 'myVrfy',
        component: () => import('@/view/MyVrfy.vue'),
        children: [
          {
            path: 'instructions',
            name: 'vrfyItro',
            component: () => import('@/view/pane/VrfyIntr.vue')
          },
          {
            path: 'step1',
            name: 'vrfyStep1',
            component: () => import('@/view/pane/VrfyStep1.vue')
          },
          {
            path: 'paypal',
            name: 'vrfyPapl',
            component: () => import('@/view/pane/VrfyPapl.vue')
          },
          {
            path: 'stripe',
            name: 'vrfyStrp',
            component: () => import('@/view/pane/VrfyStrp.vue')
          },
          {
            path: 'wallet',
            name: 'vrfyWlet',
            component: () => import('@/view/pane/WletPane.vue')
          }
        ]
      },
      {
        path: 'creations',
        name: 'myCrtrAtvt',
        component: () => import('@/view/MyCret.vue')
      },
      {
        path: 'income',
        name: 'myIncm',
        component: () => import('@/view/MyIncm.vue')
      },
      {
        path: 'coupons',
        name: 'myCupn',
        component: () => import('@/view/CupnView.vue')
      }
    ]
  },
  {
    path: '/view/error-404',
    name: 'NotFund',
    component: () => import('@/view/ErorView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/view/error-404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  console.log(`[BFOR-EACH] from: ${from.path}, to: ${to.path}`)
  if (to.path == null) {
    console.error(`[BFOR-EACH] to (x) - from: ${from.path}, to: ${to.path}`)
    next('/')
  } else if (to.path && to.path.indexOf('/stock/images/')>-1 || to.path && to.path.indexOf('/pmpt/imgs/')>-1) {
    // 외부로 부터 이미지 상세모달 접근 시
    // if (!user_stor().getPmptImgGrupId) {
    //   sessionStorage.setItem('fromExternal', 'Y')
    const item = to.query.d ? { key: 'd', val: to.query.d }
                : to.query.index ? { key: 'index', val: to.query.index } : null
    const isPay = (to.path.indexOf('/pay') > -1)
    let param = { grupId: to.params.grupId, isPay: isPay }
    if (item) {
      param.itemId = item.val
    }
    mdal_stor().openStckImgDetlPopUp(param)
    next(false)
  } else {
	  next()
  }
})

export default router
