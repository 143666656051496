<template>
  <div v-if="model.payData" class="pamt-card-wrap h-100">
    <div id="stripe-checkout" class="pb-5"></div>
  </div>
</template>

<script setup>
// ref. https://docs.stripe.com/checkout/embedded/quickstart
// ref. https://github.com/stripe-samples/accept-a-payment/blob/main/payment-element/client/vue-cva/src/components/SrCheckoutForm.vue
// ref. https://stripe.com/docs/js/elements_object/create#stripe_elements-options
// ref. https://docs.stripe.com/elements/appearance-api
// ref. https://docs.stripe.com/payments/payment-element#appearance
// ref. https://docs.stripe.com/js/elements_object/create_payment_element
import { onMounted, reactive, onBeforeUnmount } from "vue"
import { mdal_stor } from "@/stor/mdal_stor"
import axis_cstm from "@/util/axis_cstm"
import { API_ROOT, ResCd } from "@/util/comn_cnst"
import "@/assets/css/strp.css"
import { loadStripe } from "@stripe/stripe-js"
import { loadConnectAndInitialize } from "@stripe/connect-js"

const mdal = mdal_stor()
const emit = defineEmits(["loading", "closMdal"])
const prop = defineProps(["payData", "data"])
const model = reactive({
  payData: null,
  user: null,
  items: null,
  loading: true,
  messages: [],
  rtryCnt: 0
})
let stripe
let checkout

onMounted(() => {
  getParameter()
  initStripe()
})

onBeforeUnmount(() => {
  remvCkotCpnt()
})

async function initStripe() {
  let optn = {}
  if (model.payData.strpAcntId) {
    optn = { stripeAccount: model.payData.strpAcntId }
  }
  stripe = Stripe(import.meta.env.VITE_APP_STRP_PUBL_KEY, optn)

  const fetchClientSecret = async () => {
    const res = await __createCheckoutSession()
    const data = res.data
    if (data.code === ResCd.SUCCESS) {
      //console.log('fetchClientSecret', JSON.stringify(data))
      if (data.rslt && data.rslt.c1 && data.rslt.s1 && data.rslt.i1) {
        model.payData["c1"] = data.rslt.c1
        model.payData["s1"] = data.rslt.s1
        model.payData["i1"] = data.rslt.i1
        return data.rslt.c1
      } else {
        mdal.toast("Failed at initiating payment processor.<br/>Please try it again.")
        return undefined
      }
    } else {
      return undefined
    }
  }
  checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret: fetchClientSecret,
    onComplete: onPamtCplt
  })
  checkout.mount("#stripe-checkout")
  model.loading = false
  setTimeout(function () {
    emit("loading", false)
  }, 1000)

}

function __createCheckoutSession() {
  return axis_cstm()
    .post(API_ROOT + "pblc/pcse/strp", {
      grupId: model.payData.grupId,
      imgIdList: model.payData.imgIdList,
      pmptPricId: model.payData.pmptPricId,
      imgPricId: model.payData.imgPricId,
      pmptPricAmt: model.payData.pmptPricAmt,
      imgPricAmt: model.payData.imgPricAmt,
      pmptQntt: model.payData.pmptCnt,
      imgQntt: model.payData.imgCnt,
      totlPric: model.payData.totlPric
    }).then()
}

function onPamtCplt(ignoreErrMgs) {
  if (model.loading === false) {
    model.loading = true
    console.log("[PamtPaneStrp] onPamtCplt")
  }
  axis_cstm()
    .patch(API_ROOT + "pblc/pcse/stat", { s1: model.payData.s1, i1: model.payData.i1 })
    .then((res) => {
      console.log("[Ckot Sson Stat]", JSON.stringify(res.data))
      if (res.data.rslt.stat === "complete") {
        if (res.data.rslt.routPathEncd) {
          onPamtSucc(res.data.rslt.routPathEncd)
        } else {
          onPamtFailWait(ignoreErrMgs)
        }
      } else if (res.data.rslt.stat === "open") {
        onPamtFailWait(ignoreErrMgs)
      } else {
        onPamtFailStop()
      }
    })
    .catch((e) => {
      console.error("err", e.message)
      onPamtFailStop()
    })
    .finally(() => {})
}

function onPamtSucc(path) {
  model.loading = false
  setTimeout(function () {
    remvCkotCpnt()
    location.href = "/view/payment/download/" + path
  }, 2000)
}

function onPamtFailWait(ignoreErrMgs) {
  model.rtryCnt++
  if (model.rtryCnt === 10) {
    onPamtFailStop()
  }
  if (!ignoreErrMgs) {
    // mdal.toast("Payment is being<br/> processed. Please wait a moment")
  }
  setTimeout(function () {
    onPamtCplt(true)
  }, 1000)
}

function onPamtFailStop() {
  model.loading = false
  mdal.alert("Unable to process <br/>payment, please try again.")
}

function remvCkotCpnt() {
  // Destroy Checkout instance
  /*
  if (checkout) {
    checkout.unmount()
  }
  */
  if (checkout) {
    checkout.destroy()
  }
  checkout = null
}

// view/pmpt/pamt?session_id=cs_test_b1LzSrFPdQX83LBcIZCEt9hVI6ZtaxPlJ7H4uHC6fubAeOnN2R1Cy6Tcgi
/*
function doPamtProc() {

  console.warn("HANDLESUBMIT")
  if (model.loading === true) {
    return
  }

  model.loading = true

  stripe
    .confirmPayment({
      elements,
      confirmParams: {
        // return_url: `${window.location.origin}/view/pmpt/pamt`
        // return_url: `${location.href}`
      }
    })
    .then(function (res) {
      // {"error":{"code":"incomplete_email","type":"validation_error","message":"이메일 주소가 불완전합니다."}}
      if (res.error) {
        // Inform the customer that there was an error.
        if (res.error.type === "card_error" || res.error.type === "validation_error") {
          console.error("payment req error", res.error.message)
          model.messages.push(res.error.message)
        } else {
          model.messages.push("An unexpected error occured.")
        }
      } else {
        // No error
      }
    })
    .catch(function (error) {
      // Inform the customer that there was an error.
      console.error("catch err", JSON.stringify(error))
      console.error("payment catch error", error.message)
      if (error.type === "card_error" || error.type === "validation_error") {
        model.messages.push(error.message)
      } else {
        model.messages.push("An unexpected error occured.")
      }
    })
    .finally(() => {
      model.loading = false
    })
}
*/

function getParameter() {
  if (!prop.data) {
    return
  }
  model.payData = prop.data // paydata
  model.user = model.payData.user
  model.items = model.payData.items
  console.log("[STEP2] getParameter from prev pane", JSON.stringify(model.payData))
  console.log("[STEP2] user", JSON.stringify(model.user))
}

// defineExpose({ doPamtProc })
</script>

<style scoped>
.title-h3 svg {
  margin-right: 9px;
}

.rect-box h6 {
  color: #585858;
  line-height: 35px;
}

.pamt-card-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  max-height: 100%;
  padding: 0;
}

.thumbnail > img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.table th {
  background-color: #f0f0f0;
  text-align: center;
}

.table td {
  text-align: end;
}

.pos-abs-center svg {
  font-size: 5rem;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.btn-pmpt svg {
  height: 100%;
  opacity: 0.6;
}

.cart-desc p {
  height: 33px;
  margin-bottom: 0;
}

hr {
  margin: 0.7rem 0;
}

@media (max-width: 767.98px) {
  #stripe-checkout {
    height: 100%;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .pamt-card-wrap {
    height: 100%;
  }

  .CheckoutPaymentForm {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .CheckoutPaymentForm > *,
  .CheckoutPaymentForm > * > *,
  .CheckoutPaymentForm > * > * > * {
    height: 100%;
  }
}

@media (min-width: 992px) {
}
</style>
